import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Button, Modal, Descriptions } from 'antd';
import dayjs from 'dayjs';
import UserForm from './UserForm';
import { useAuth } from '../../context/useAuth';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UserGeneral = ({
  user,
  levels,
  heroes,
  trainers,
  editUserHandler,
  searchNickname,
  setSearchNickname,
  users,
  clubs,
  isDeleted,
  isGuestClient
}) => {
  const editors = ['admin'];
  const [formForEdit] = Form.useForm();
  const [editModal, setEditModal] = React.useState(false);
  const { getRole } = useAuth();
  const role = getRole();

  const renderField = (field, index) => {
    const backgroundColor = index % 2 === 0 ? '#fafafa' : '#ebebeb';
    const labelStyle = { width: '200px', backgroundColor };

    if (field.value === 'birthday') {
      const birthday = dayjs(user[field.value]);
      return (
        <Descriptions.Item label={field.label} labelStyle={labelStyle}>
          {birthday.isValid() ? birthday.format('DD.MM.YYYY') : '-'}
        </Descriptions.Item>
      );
    }

    if (typeof user[field.value] === 'boolean') {
      return (
        <Descriptions.Item label={field.label} labelStyle={labelStyle}>
          {user[field.value] === false ? 'Нет' : 'Да'}
        </Descriptions.Item>
      );
    }
    return (
      <Descriptions.Item label={field.label} labelStyle={labelStyle}>
        {user[field.value]}
      </Descriptions.Item>
    );
  };

  const fields = [
    { value: 'nickname', label: 'Никнейм' },
    { value: 'email', label: 'Email' },
    { value: 'sex', label: 'Пол' },
    { value: 'levelName', label: 'Уровень' },
    { value: 'heroName', label: 'Персонаж' },
    { value: 'phoneNumber', label: 'Номер телефона' },
    { value: 'birthday', label: 'Дата рождения' },
    { value: 'points', label: 'Очки' },
    // { value: 'isInvictusClient', label: 'Член клуба?' },
    // { value: 'tickets', label: 'Количество билетов(чк)' },
    // { value: 'outsiderTickets', label: 'Количество билетов(не чк)' },
    // { value: 'cardsCnt', label: 'Кол-во карт' },
    { value: 'clubId', label: 'Клуб'},
    { value: 'isDeleted', label: 'Удален'},
    { value: 'isGuestClient', label: 'Гость'}
    // cardCnt
    // paymentCnt
  ];

  return (
    <MainContainer>
      <ButtonContainer>
        <h2> Основные </h2>
        {editors.includes(role) && (
          <Button type="primary" onClick={() => setEditModal(true)}>
            Редактировать
          </Button>
        )}
      </ButtonContainer>
      <Descriptions
        title="Информация о пользователе"
        bordered
        column={1}
        size="small"
      >
        {fields.map((field, index) => {
          return renderField(field, index);
        })}
      </Descriptions>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false);
          }}
          onOk={() => editUserHandler(formForEdit, user.id, setEditModal)}
        >
          <UserForm
            form={formForEdit}
            nickname={user?.nickname}
            sex={user.sex}
            phoneNumber={user.phoneNumber}
            email={user.email}
            birthday={user.birthday}
            hero={user.hero?.id}
            level={user.level?.id}
            trainer={user.trainer?.id}
            role={user.role}
            sharedUser={user?.sharedUser?.id}
            heroPathLevels={user?.heroPathLevels}
            userAvatarURL={user.userAvatarURL}
            levels={levels}
            heroes={heroes}
            trainers={trainers}
            searchNickname={searchNickname}
            setSearchNickname={setSearchNickname}
            users={users}
            clubs={clubs}
            userClub={user.club ? user.club.id : null}
            isDeleted={isDeleted}
            isGuestClient={isGuestClient}
            guest={user.isGuestClient}
          />
        </Modal>
      )}
    </MainContainer>
  );
};

UserGeneral.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    nickname: PropTypes.string,
    sex: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    birthday: PropTypes.string,
    hero: PropTypes.string,
    trainer: PropTypes.string,
    level: PropTypes.string,
    role: PropTypes.string,
    userAvatarURL: PropTypes.string,
  }).isRequired,
  editUserHandler: PropTypes.func.isRequired,
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  heroes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default UserGeneral;
