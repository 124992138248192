import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import User from './User';
import Loading from '../shared/Loading';
import withMainLayout from '../../hocs/withMainLayout';
import { useLoading } from '../../context/useLoading';
import { GET_ADMINS, UPDATE_USER_REQUEST } from '../support/Support.queries';

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      username
      nickname
      email
      role
      points
      club {
            id
        }
      isDeleted
      isGuestClient
      level {
        id
        name
      }
      sex
      hero {
        id
        name
      }
      cards {
        id
        name
      }
      trainer {
        id
        nickname
      }
      sharedUser {
        id
        shortCode
      }
      tickets
      outsiderTickets
      marathonTickets
      assessmentTickets
      educationTickets
      guestTickets
      isInvictusClient
      birthday
      phoneNumber
      userAvatarURL
      shortCode
      # club {
      #   id
      # }
      heroPathLevels {
        identifier
        degree
      }
    }

    levels {
      id
      name
    }
    heros {
      id
      name
      identifier
    }
    usersByRole(role: "trainer") {
      id
      role
      nickname
    }
  }
`;

const GET_USERS = gql`
  query find($query: UsersQuery, $pagination: PaginationQuery) {
    findUsers(queryUsers: $query, pagination: $pagination) {
      users {
        id
        username
        nickname
        email
        phoneNumber
        shortCode
      }
      total
      limit
      page
      pages
    }
  }
`;

const EDIT_USER = gql`
  mutation editUser($id: ID!, $input: EditUserInput) {
    editUser(id: $id, input: $input) {
      id
      username
      nickname
      email
      role
      points
      level {
        id
        name
      }
      sex
      hero {
        id
        name
      }
      trainer {
        id
        nickname
        role
      }
      tickets
      birthday
      phoneNumber
      userAvatarURL
      isDeleted
      isGuestClient
    }
  }
`;

const GET_CLUBS = gql`
  query clubs {
    clubs {
      id
      name
    }
  }
`;

export const GET_REQUEST_QUESTION = gql`
  query AllRequestQuestions($userId: ID!) {
    allRequestQuestions(userId: $userId) {
      requestQuestions {
        id
        status
        event
        marathonEvent
        affectedUser {
          id
        }
        user {
          id
          firstName
          nickname
          phoneNumber
          username
        }
        requestCategory {
          name
        }
        requestSubcategory {
          name
        }
        queries {
          media
          desc
          title
          created_at
          adminId {
            nickname
          }
        }
        assigned {
          id
          nickname
        }
      }
    }
  }
`;

const UserContainer = () => {
  const { id: userId } = useParams();
  const { showLoading, hideLoading } = useLoading();

  const [user, setUser] = useState(null);

  const [levels, setLevels] = useState([]);
  const [heroes, setHeroes] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchNickname, setSearchNickname] = React.useState(null);
  const [clubs, setClubs] = useState();
  const [userClub, setUserClub] = useState();
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  const [admins, setAdmins] = React.useState([]);
  const [choosedAdmin, setChoosedAdmin] = React.useState('');
  const [updateType, setUpdateType] = React.useState('');
  const [answer, setAnswer] = React.useState('');

  const renderStatus = (status) => {
    switch (status) {
      case 'inProgress':
        return `В обработке`;
      case 'notViewed':
        return `Не прочитано`;
      case 'moreDataForAdmin':
        return `Дополняется`;
      case 'finished':
        return 'Решено';
      default:
        return 'Не прочитано';
    }
  };

  const { data, error, loading, refetch } = useQuery(GET_USER, {
    variables: {
      id: userId,
    },
  });

  const { data: requestQuestion } = useQuery(GET_REQUEST_QUESTION, {
    variables: { userId },
  });

  const [
    getUsers,
    {
      loading: loadingUsers,
      error: errorUsers,
      data: dataUsers,
      refetch: refetchUsers,
    },
  ] = useLazyQuery(GET_USERS);

  const [editUser, { data: editUserData, error: editUserError }] = useMutation(
    EDIT_USER,
    {
      onCompleted() {
        refetch();
      },
    }
  );
  const { data: clubsData, loading: clubsLoading } = useQuery(GET_CLUBS);

  const {
    data: adminsData,
    loading: adminsLoading,
    error: adminsError,
  } = useQuery(GET_ADMINS);

  const addAdminForRequest = () => {
    setSelectedRequest({
      ...selectedRequest,
      status: updateType === 'addAdmin' ? 'inProgress' : selectedRequest.status,
      assigned: {
        id: updateType === 'addAdmin' ? user?.id : choosedAdmin?.id,
        nickname:
          updateType === 'addAdmin' ? user?.nickname : choosedAdmin?.nickname,
      },
    });
  };

  const createAdminAnswer = () => {
    setSelectedRequest({
      ...selectedRequest,
      status: updateType === 'adminAnswer' ? 'moreDataForAdmin' : 'finished',
      isAnswerViewed: false,
      lastAnswerDate: dayjs().toISOString(),
      queries: [
        ...selectedRequest.queries,
        {
          desc: answer,
          created_at: dayjs(),
          adminId: { id: user?.id, nickname: user?.nickname },
        },
      ],
    });
    setAnswer('');
  };

  const [updateRequestQuestion, { loading: loadingUpdateRequest }] =
    useMutation(UPDATE_USER_REQUEST, {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      refetchQueries: [{ query: GET_REQUEST_QUESTION }],
      onCompleted() {
        if (updateType === 'addAdmin' || updateType === 'changeAdmin') {
          addAdminForRequest();
        } else if (
          updateType === 'adminAnswer' ||
          updateType === 'adminReport'
        ) {
          createAdminAnswer();
        }
      },
      onError(err) {
        toast.error(`Error ${err.message}`);
      },
    });

  React.useEffect(() => {
    if (adminsData && !adminsLoading && !adminsError) {
      setAdmins(adminsData.usersByRole);
    }
  }, [adminsData, adminsLoading, adminsError]);

  React.useEffect(() => {
    console.log(data, error, loading);
    if (data && !error && !loading) {
      setUser(data.user);
      setLevels(data.levels);
      setHeroes(data.heros);
      setTrainers(data.usersByRole);
    }

    if (editUserError) {
      toast.error(editUserError.message);
    }

    if(requestQuestion) {      
      setSelectedRequest(requestQuestion?.allRequestQuestions?.requestQuestions?.find(item => renderStatus(item?.status)));
    }
  }, [data, loading, error, editUserData, editUserError, requestQuestion]);

  React.useEffect(() => {
    getUsers({
      variables: {
        query: {
          text: user?.sharedUser?.shortCode,
        },
        pagination: {
          page: 1,
          limit: 10,
        },
      },
    });
  }, [user]);

  React.useEffect(() => {
    refetchUsers({
      query: {
        text: searchNickname,
      },
      pagination: {
        page: 1,
        limit: 10,
      },
    });
  }, [searchNickname]);

  React.useEffect(() => {
    if (dataUsers && dataUsers.findUsers && !loadingUsers) {
      setUsers(dataUsers.findUsers.users);
    } else if (errorUsers) {
      console.log(`error `, errorUsers);
    }
  }, [dataUsers, loadingUsers, errorUsers]);

  React.useEffect(() => {
    if (clubsData && !clubsLoading && user) {  
      setClubs(clubsData.clubs);
      setUserClub(clubsData?.clubs.find(item => item?.id === user?.club?.id));
    }
  }, [clubsData, clubsLoading, user]);
  
  const editUserHandler = (form, userIdArg, closeFunc) => {
    showLoading();
    form
      .validateFields()
      .then((val) => {
        editUser({
          variables: {
            id: userIdArg,
            input: {
              ...val,
              nickname: val.nickname,
              username: val.email,
              email: val.email,
              phoneNumber: val.phoneNumber,
              sex: val.sex,
              trainer: val.trainer || null,
              // when it references other models, and if this field is empty, you should pass null
              birthday: val.birthday
                ? dayjs(val.birthday).format('DD-MM-YYYY')
                : dayjs().format('DD-MM-YYYY'),
              hero: val.hero || null,
              level: val.level || null,
              role: val.role,
              userAvatarURL: val.userAvatarURL,
              club: val.club,
              isDeleted: val.isDeleted === 'Да' ? true : val.isDeleted === 'Нет' ? false : val.isDeleted,
              isGuestClient: val.isGuestClient === 'Да' ? true : val.isGuestClient === 'Нет' ? false : val.isGuestClient,
              heroPathLevels: val.heroPathLevels.map((data) => {
                return {
                  identifier: data?.identifier,
                  degree: parseInt(data?.degree),
                  isViewed: true,
                };
              }),
            },
          },
        });
      })
      .then(() => {
        hideLoading();
        closeFunc(false);
        toast.success('Пользователь изменен');
      });
  };

  const changeAdmin = () => {
    if (selectedRequest && choosedAdmin?.id && user) {
      setUpdateType('changeAdmin');
      updateRequestQuestion({
        variables: {
          id: selectedRequest?.id,
          input: {
            assigned: choosedAdmin?.id,
          },
        },
      });
    }
  };

  const removeTypename = (objects) => {
    return objects.map((obj) => {
      const { __typename, ...rest } = obj;
      if (rest?.adminId) {
        const { __typename: type, ...restAdminId } = rest.adminId;
        return { ...rest, adminId: restAdminId?.id };
      }
      return rest;
    });
  };

  const onSubmitAdminAnswer = (type) => {
    if (type === 'moreData') {
      setUpdateType('adminAnswer');
    } else {
      setUpdateType('adminReport');
    }
    const newQuery = {
      title:
        type === 'moreData'
          ? 'Пожалуйста, дополните ваш запрос'
          : 'Ваш запрос закрыт',
      desc: answer,
      adminId: user?.id,
      created_at: dayjs().toISOString(),
    };
    const updatedObjArr = removeTypename(selectedRequest.queries);
    const updatedQueries = [...updatedObjArr, newQuery];
    updateRequestQuestion({
      variables: {
        id: selectedRequest?.id,
        input: {
          user: selectedRequest?.user?.id,
          status: type === 'moreData' ? 'moreDataForAdmin' : 'finished',
          isAnswerViewed: false,
          lastAnswerDate: dayjs().toISOString(),
          queries: updatedQueries,
        },
      },
    });
  };

  if (!user) {
    return <Loading />;
  }

  return (
    <User
      {...{
        user: {
          ...user,
          levelName: user.level?.name,
          heroName: user.hero?.name,
          trainerName: user.trainer?.nickname,
          cardsCnt: user.cards?.length,
          clubId: userClub?.name,
          isDeleted: user.isDeleted,
          isGuestClient: user?.isGuestClient,
        },
        levels,
        heroes,
        trainers,
        editUserHandler,
        searchNickname,
        setSearchNickname,
        users,
        clubs,
        setShowRequestModal,
        showRequestModal,
        renderStatus,
        selectedRequest,
        admins,
        setChoosedAdmin,
        changeAdmin,
        loadingUpdateRequest,
        onSubmitAdminAnswer,
        setAnswer
      }}
    />
  );
};

UserContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withMainLayout(UserContainer);
