import React from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  InfoCircleOutlined,
  CalendarOutlined,
  HistoryOutlined,
  DollarOutlined,
  DropboxOutlined,
  SwapOutlined,
  TrophyOutlined,
  ClockCircleOutlined,
  StarOutlined,
} from '@ant-design/icons';

import UserGeneral from './UserGeneral';
import UserBookings from './UserBookings';
import UserPoints from './UserPoints';
import UserPayments from './UserPayments';
import UserTransactions from './UserTransactions';
import UserInventoryItems from './UserInventoryItems';
import UserMarathonEvents from './UserMarathonEvents';
import UserClubEntries from './UserClubEntries';
import UserHeroPasses from './UserHeroPasses';
import SupportRequestModal from '../support/SupportRequestModal';

const { Sider, Content } = Layout;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const StyledHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 24px 0;
  display: flex;
  justify-content: space-between;
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  padding-top: 16px;
`;

const StyledLayout = styled(Layout)`
  padding: 0 24px 24px;
  background: #fff;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  margin: 0px;
  min-height: 280px;
`;

const User = ({
  user,
  userBookings,
  userPayments,
  userTransactions,
  levels,
  heroes,
  trainers,
  editUserHandler,
  handleAddTickets,
  handleAddTicketsBySales,
  handleAddHeroPass,
  handleUpdateUser,
  searchNickname,
  setSearchNickname,
  users,
  clubs,
  isDeleted,
  isGuestClient,
  setShowRequestModal,
  showRequestModal,
  renderStatus,
  selectedRequest,
  admins,
  setChoosedAdmin,
  changeAdmin,
  loadingUpdateRequest,
  onSubmitAdminAnswer,
  setAnswer
}) => {
  const [currentTab, setCurrentTab] = React.useState(1);

  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey);
  };

  return (
    <Layout>
      <SupportRequestModal
        setShowRequestModal={setShowRequestModal}
        showRequestModal={showRequestModal}
        renderStatus={renderStatus}
        selectedRequest={selectedRequest}
        admins={admins}
        setChoosedAdmin={setChoosedAdmin}
        pressChangeAdmin={changeAdmin}
        loading={loadingUpdateRequest}
        onSubmitAdminAnswer={onSubmitAdminAnswer}
        setAnswer={setAnswer}
      />
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          <Link to="/users" href="/">
            Пользователи
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item> {user?.nickname}</Breadcrumb.Item>
      </StyledBreadcrumb>

      <StyledHeader>
        <h2>Пользователь</h2>
      </StyledHeader>

      <Layout>
        <Sider>
          <StyledMenu mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              icon={<InfoCircleOutlined />}
              key="1"
              onClick={() => handleTabChange(1)}
            >
              Основные
            </Menu.Item>
            <Menu.Item
              icon={<CalendarOutlined />}
              key="2"
              onClick={() => handleTabChange(2)}
            >
              Посещения
            </Menu.Item>
            <Menu.Item
              icon={<DollarOutlined />}
              key="3"
              onClick={() => handleTabChange(3)}
            >
              Пополнения
            </Menu.Item>
            <Menu.Item
              icon={<SwapOutlined />}
              key="4"
              onClick={() => handleTabChange(4)}
            >
              История баланса
            </Menu.Item>
            <Menu.Item
              icon={<DropboxOutlined />}
              key="5"
              onClick={() => handleTabChange(5)}
            >
              Инвентарь
            </Menu.Item>
            <Menu.Item
              icon={<ClockCircleOutlined />}
              key="6"
              onClick={() => handleTabChange(6)}
            >
              Время в зале
            </Menu.Item>
            <Menu.Item
              icon={<TrophyOutlined />}
              key="7"
              onClick={() => handleTabChange(7)}
            >
              Марафоны
            </Menu.Item>

            <Menu.Item
              icon={<StarOutlined />}
              key="8"
              onClick={() => handleTabChange(8)}
            >
              HeroPass
            </Menu.Item>
          </StyledMenu>
        </Sider>
        <StyledLayout>
          <StyledContent className="site-layout-background">
            {currentTab === 1 && (
              <UserGeneral
                user={user}
                editUserHandler={editUserHandler}
                levels={levels}
                heroes={heroes}
                trainers={trainers}
                searchNickname={searchNickname}
                setSearchNickname={setSearchNickname}
                users={users}
                clubs={clubs}
                isDeleted={isDeleted}
                isGuestClient={isGuestClient}
              />
            )}
            {currentTab === 2 && <UserBookings userId={user.id} />}
            {currentTab === 3 && <UserPayments userId={user.id} />}
            {currentTab === 4 && <UserTransactions user={user} />}
            {currentTab === 5 && <UserInventoryItems userId={user.id} />}
            {currentTab === 6 && <UserClubEntries userId={user.id} />}
            {currentTab === 7 && <UserMarathonEvents userId={user.id} />}
            {currentTab === 8 && <UserHeroPasses userId={user.id} />}
          </StyledContent>
        </StyledLayout>
      </Layout>
    </Layout>
  );
};

User.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    nickname: PropTypes.string,
  }).isRequired,
  userBookings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  userPayments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  userTransactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  heroes: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      nickname: PropTypes.string,
    })
  ).isRequired,
  editUserHandler: PropTypes.func.isRequired,
  handleAddTickets: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      cost: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      ticketType: PropTypes.string.isRequired,
      countClasses: PropTypes.number.isRequired,
    })
  ).isRequired,
  handleAddTicketsBySales: PropTypes.func.isRequired,
};

export default User;
