import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Image, Input } from 'antd';
import { FormControl } from '@mui/material';
import { styled as styledMui } from '@mui/system';
import { LoadingOutlined, DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Wrapper = styled.div`
  background-color: #fff;
  position: fixed;
  transition: all 0.3s;
  top: 0;
  right: ${(props) => (props.show ? '0' : '-50%')};
  width: 450px;
  height: 100%;
  z-index: 1;
  overflow-y: scroll;
`;

const WrapperCollapse = styled.div`
  background-color: #fff;
  position: fixed;
  transition: all 0.5s;
  top: 0;
  right: ${(props) => (props.show ? '0' : '-50%')};
  width: 50px;
  height: 100%;
  z-index: 4;
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px 24px;
  position: sticky;
  top: 0;
  background: #ffffff;
`;

const Title = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 20px;
  line-height: 24px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
const BodyTitle = styled.div`
  font-family: 'GraphikLCGMedium';
  font-size: 16px;
  line-height: 16px;
  margin-top: 3%;
`;

const Field = styled.div`
  margin-right: auto;
  font-family: 'GraphikLCGMedium';
  font-size: 14px;
  line-height: 16px;
  color: #00000080;
`;

const Value = styled.div`
  font-family: 'GraphikLCGRegular';
  font-size: 14px;
  line-height: 16px;
`;

const Content = styled.div`
  display: flex;
  margin-top: 7%;
  justify-content: space-between;
`;

const Section = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;
const NewSub = styled.button`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 12px;
  margin-top: 8px;
  cursor: pointer;
  width: 100%;
`;

const Nickname = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  color: black;
`;

const Desc = styled.div`
  font-size: 13px;
  font-family: 'GraphikLCGRegular';
  color: black;
  line-height: 16px;
  margin-bottom: 8px;
  white-space: pre-wrap;
  
  p {
    text-indent: 20px;
    margin-top: 8px;
  }
`;

const Time = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'GraphikLCGMedium';
`;

const DiologHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Diolog = styled.div`
  margin-top: 24px;
`;

const DiologContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const CommentText = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: 'GraphikLCGMedium';
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
`;

const AssignedCont = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
`;

const Text = styled.button`
  font-size: 14px;
  line-height: 16px;
  color: rgba(111, 44, 255, 1);
  cursor: pointer;
  border: 0px;
  padding: 0px;
  font-family: 'GraphikLCGMedium';
  background-color: white;
`;

const StyledFormControl = styledMui(FormControl)(({ theme }) => ({
  minWidth: 165,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: 8,
  backgroundColor: 'transparent',
  padding: 3,
  justifyContent: 'center',
}));

const SupportRequestModal = ({
  setSelectedRequest,
  selectedRequest,
  setShowRequestModal,
  showRequestModal,
  renderStatus,
  setAnswer,
  onSubmitAdminAnswer,
  admins,
  setChoosedAdmin,
  choosedAdmin,
  pressChangeAdmin,
  loading,
}) => {
  const navigate = useNavigate();
  const [isCommentSection, setIsCommentSection] = React.useState('');
  const [changeAdmin, setChangeAdmin] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [userText, setUserText] = React.useState('');
  const [initText, setInitText] = React.useState(false);
  const [isModified, setIsModified] = React.useState(false);

  const initialText = `Доброго времени суток, ${selectedRequest?.user?.firstName || selectedRequest?.user?.nickname}!\n----> Ваш текст\nСпасибо, что вы с нами. С заботой, Hero's Journey.`;

  React.useEffect(() => {
    setUserText(initText ? initialText : '');
  }, [initText]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };  

  const handleChangeTextArea = (e) => {
    const newText = e.target.value;
    setUserText(newText);
    setIsModified(newText !== initialText);
  };

  return (
    <>
      <WrapperCollapse show={!showRequestModal}>
        <Header style={{padding: '30px 0px 0px 0px', border: 'none'}}>
          <DoubleLeftOutlined 
            style={{
              cursor: 'pointer',
              marginLeft: 'auto',
              fontSize: '16px'
            }}
            onClick={() => {
              setShowRequestModal(true);
            }}
          />
        </Header>
      </WrapperCollapse>
      <Wrapper show={showRequestModal}>
        <Header>
          <Title>Запрос</Title>
          <DoubleRightOutlined 
            style={{
              marginLeft: 'auto',
              cursor: 'pointer'
            }}
            onClick={() => {
              setShowRequestModal(false);
            }}
          />
        </Header>
        <Body>
          <Section>
          <Content style={{marginTop: 0}}>
            <BodyTitle>Данные о пользователе</BodyTitle>
            <Text
              onClick={()=> { 
                  const win = window.open(`/users/${selectedRequest?.user?.id}`,'_blank');
                  win.focus();
              }}
              style={{marginTop: '3%'}}
            >
              Перейти
            </Text>
          </Content>
            <Content>
              <Field>ID</Field>
              <Value>{selectedRequest?.user?.id}</Value>
            </Content>
            <Content>
              <Field>Имя</Field>
              <Value>{selectedRequest?.user?.firstName}</Value>
            </Content>
            <Content>
              <Field>Никнейм</Field>
              <Value>{selectedRequest?.user?.nickname}</Value>
            </Content>
            <Content>
              <Field>Номер</Field>
              <Value>{selectedRequest?.user?.phoneNumber}</Value>
            </Content>
            <Content>
              <Field>Email</Field>
              <Value>{selectedRequest?.user?.username}</Value>
            </Content>
          </Section>
          {
            selectedRequest?.affectedUser &&
            <Section>
              <Content style={{marginTop: 0}}>
                <BodyTitle>Данные о пострадавшем пользователе</BodyTitle>
                <Text
                  onClick={()=> { 
                      const win = window.open(`/users/${selectedRequest?.affectedUser?.id}`,'_blank');
                      win.focus();
                  }}
                  style={{marginTop: '3%'}}
                >
                  Перейти
                </Text>
              </Content>
              <Content>
                <Field>ID</Field>
                <Value>{selectedRequest?.affectedUser?.id}</Value>
              </Content>
            </Section>
          }
          {
            selectedRequest?.event && 
            <Section>
              <Content style={{marginTop: 0}}>
                <BodyTitle>Данные о тренеровке</BodyTitle>
                <Text
                  onClick={()=> { 
                    const queryParams = new URLSearchParams({
                      userId: selectedRequest?.user?.id,
                      event: selectedRequest?.event,
                    });
                    const win = window.open(`/events/${selectedRequest?.event}?${queryParams.toString()}`, '_blank');
                    win.focus();
                  }}
                  style={{marginTop: '3%'}}
                >
                  Перейти
                </Text>
              </Content>
              <Content>
                <Field>ID</Field>
                <Value>{selectedRequest?.event}</Value>
              </Content>
            </Section>
          }
          {
            selectedRequest?.marathonEvent &&
            <Section>
              <Content style={{marginTop: 0}}>
                <BodyTitle>Данные о марафоне</BodyTitle>
                {/* <Text
                  onClick={()=> { 
                      const win = window.open(`/marathonEvent/${selectedRequest?.event}/${selectedRequest?.marathonEvent}`,'_blank');
                      win.focus();
                  }}
                  style={{marginTop: '3%'}}
                >
                  Перейти
                </Text> */}
              </Content>
              <Content>
                <Field>ID</Field>
                <Value>{selectedRequest?.marathonEvent}</Value>
              </Content>
            </Section>
          }
          <Section>
            <BodyTitle>Запрос</BodyTitle>
            <Content>
              <Field>Категория</Field>
              <Value>{selectedRequest?.requestCategory?.name}</Value>
            </Content>
            <Content>
              <Field>Подкатегория</Field>
              <Value>{selectedRequest?.requestSubcategory?.name}</Value>
            </Content>
            <Content>
              <Field>Статус</Field>
              <Value>{renderStatus(selectedRequest?.status)}</Value>
            </Content>
            <Content style={{ display: changeAdmin ? 'block' : 'flex' }}>
              <Field>Ответственный</Field>
              {changeAdmin ? (
                <>
                  <StyledFormControl
                    variant="standard"
                    style={{ marginTop: 10, width: '100%' }}
                  >
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      value={value || selectedRequest?.assigned?.id}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'Without label' }}
                      disableUnderline
                      style={{
                        paddingLeft: 10,
                        fontFamily: 'GraphikLCGMedium',
                        fontSize: 14,
                      }}
                    >
                      {admins?.map((item, index) => (
                        <MenuItem
                          key={item.id}
                          style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                          value={item.id}
                          onClick={() => {
                            setChoosedAdmin(item);
                          }}
                        >
                          {item?.nickname}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                  <NewSub
                    style={{ backgroundColor: '#6F2CFF', color: 'white' }}
                    onClick={() => {
                      pressChangeAdmin();
                      setChangeAdmin(false);
                    }}
                  >
                    Подтвердить
                  </NewSub>
                  <NewSub
                    onClick={() => {
                      setChangeAdmin(false);
                      setValue('');
                    }}
                  >
                    Отмена
                  </NewSub>
                </>
              ) : (
                <AssignedCont>
                  <Value>{selectedRequest?.assigned?.nickname}</Value>
                  <Text
                    onClick={() => {
                      setChangeAdmin(true);
                    }}
                    style={{}}
                  >
                    Изменить
                  </Text>
                </AssignedCont>
              )}
            </Content>
          </Section>
          <Section>
            <BodyTitle>Диалог</BodyTitle>
            <DiologContainer>
              {selectedRequest?.queries?.map((item, index) => (
                <Diolog key={index}>
                  <DiologHeader>
                    <Nickname
                      style={{
                        color: item?.adminId ? 'rgba(0, 0, 0, 0.5)' : 'black',
                      }}
                    >
                      {item?.adminId
                        ? item?.adminId?.nickname
                        : selectedRequest?.user?.nickname}
                    </Nickname>
                    <Time>
                      {dayjs(item?.created_at).format('DD.MM.YY HH:mm')}
                    </Time>
                  </DiologHeader>
                  <Desc dangerouslySetInnerHTML={{ __html: item?.desc.split('\n').map(line => `<p>${line}</p>`).join('') }} />
                  <div>
                    <Image.PreviewGroup>
                      {item?.media?.map((img, index) => (
                        <Image key={index} width={64} src={img} />
                      ))}
                    </Image.PreviewGroup>
                  </div>
                </Diolog>
              ))}
              {loading && (
                <Spin
                  style={{ alignSelf: 'center', width: '100%' }}
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              )}
            </DiologContainer>
            {isCommentSection ? (
              <DiologContainer>
                <CommentText>
                  {isCommentSection === 'moreData'
                    ? 'Опишите, какую информацию нужно предоставить клиенту'
                    : 'Объясните причину закрытия запроса и какое было принято решение'}
                </CommentText>
                <Input.TextArea
                  rows={3}
                  style={{ borderRadius: 8 }}
                  value={userText}
                  onChange={handleChangeTextArea}
                  placeholder="Введите ваш текст здесь..."
                />
                <NewSub
                  style={{ backgroundColor: !isModified ? 'gray' : '#6F2CFF', color: 'white', cursor: !isModified ? 'not-allowed' : 'pointer' }}
                  disabled={!isModified}
                  onClick={() => {
                    onSubmitAdminAnswer(isCommentSection);
                    setIsCommentSection('');
                    setInitText(false);
                    setAnswer(userText);
                    setIsModified(false);
                  }}
                >
                  Подтвердить
                </NewSub>
                <NewSub
                  onClick={() => {
                    setIsCommentSection('');
                    setInitText(false);
                  }}
                >
                  Отмена
                </NewSub>
              </DiologContainer>
            ) : (
              selectedRequest?.status !== 'finished' && (
                <>
                  <NewSub
                    onClick={() => {
                      setIsCommentSection('moreData');
                      setUserText('');
                      setInitText(true);
                    }}
                  >
                    Попросить дополнить
                  </NewSub>
                  <NewSub
                    onClick={() => {
                      setIsCommentSection('closeRequest');
                      setInitText(false);
                    }}
                  >
                    Закрыть запрос
                  </NewSub>
                </>
              )
            )}
          </Section>
        </Body>
      </Wrapper>
    </>
  );
};

export default SupportRequestModal;
